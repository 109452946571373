import SafeImage from '@ui/SafeImage';
import { logEvent } from '@util/analytics';
import useLogWhenInView from '@util/hooks/useLogWhenInView';
import Link from 'next/link';
import { useRef } from 'react';
import Arrow from './icons/buttons/ArrowButton';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

interface PopularCategoriesProps {
  variant?: 'main' | 'mtb';
}

const PopularCategories = ({ variant = 'main' }: PopularCategoriesProps) => {
  const ref = useLogWhenInView(() => {
    logEvent('view_item_list', {
      item_list_id: 'popular_categories',
      item_list_name: 'Popular Categories',
      item_category: 'popular_categories',
      items: [
        {
          item_id: 'boots',
          item_name: 'Boots',
          item_category: 'popular_categories',
        },
        {
          item_id: 'gear',
          item_name: 'Gear',
          item_category: 'popular_categories',
        },
        {
          item_id: 'exhaust',
          item_name: 'Exhaust',
          item_category: 'popular_categories',
        },
        {
          item_id: 'suspension',
          item_name: 'Suspension',
          item_category: 'popular_categories',
        },
        {
          item_id: 'protective',
          item_name: 'Protective',
          item_category: 'popular_categories',
        },
        {
          item_id: 'wheels',
          item_name: 'Wheels',
          item_category: 'popular_categories',
        },
        {
          item_id: 'collectibles',
          item_name: 'Collectibles',
          item_category: 'popular_categories',
        },
      ],
    });
  });

  const categories =
    variant === 'main'
      ? [
          {
            href: '/shop/riding-gear/dirt-bike-and-motocross-boots',
            src: 'https://cdn.mxlocker.com/categories/boots.webp',
            alt: 'Boots',
            label: 'Boots',
          },
          {
            href: '/shop/riding-gear/gear',
            src: 'https://cdn.mxlocker.com/categories/gear%20new.webp',
            alt: 'Gear',
            label: 'Gear',
          },
          {
            href: '/shop/dirt-bike-parts/exhaust',
            src: 'https://cdn.mxlocker.com/categories/exhaust.webp',
            alt: 'Exhaust',
            label: 'Exhaust',
          },
          {
            href: '/shop/dirt-bike-parts/suspension',
            src: 'https://cdn.mxlocker.com/categories/suspension.webp',
            alt: 'Suspension',
            label: 'Suspension',
          },
          {
            href: '/shop/riding-gear/protective',
            src: 'https://cdn.mxlocker.com/categories/protective.webp',
            alt: 'Protective',
            label: 'Protective',
          },
          {
            href: '/shop/dirt-bike-parts/wheels',
            src: 'https://cdn.mxlocker.com/categories/wheels.webp',
            alt: 'Wheels',
            label: 'Wheels',
          },
          {
            href: '/shop/collectibles/',
            src: 'https://cdn.mxlocker.com/categories/collectibles.webp',
            alt: 'Collectibles',
            label: 'Collectibles',
          },
        ]
      : [
          {
            href: '/shop/mountain-bikes/accessories',
            src: 'https://cdn.mxlocker.com/categories/accessories-mtb.webp',
            alt: 'Accessories',
            label: 'Accessories',
          },
          {
            href: '/shop/mountain-bikes/bikes',
            src: 'https://cdn.mxlocker.com/categories/bikes-mtb.webp',
            alt: 'Bikes',
            label: 'Bikes',
          },
          {
            href: '/shop/mountain-bikes/gear',
            src: 'https://cdn.mxlocker.com/categories/gears-mtb.webp',
            alt: 'Gear',
            label: 'Gear',
          },
          {
            href: '/shop/mountain-bikes/parts',
            src: 'https://cdn.mxlocker.com/categories/parts-mtb.webp',
            alt: 'Parts',
            label: 'Parts',
          },
          // {
          //   href: '/shop/mountain-bikes/helmets',
          //   src: 'https://cdn.mxlocker.com/categories/helmets-mtb.webp',
          //   alt: 'Helmets',
          //   label: 'Helmets',
          // },
          // {
          //   href: '/shop/dirt-bike-parts/gloves',
          //   src: 'https://cdn.mxlocker.com/categories/gloves-mtb.webp',
          //   alt: 'Gloves',
          //   label: 'Gloves',
          // },
        ];
  const container = useRef<HTMLDivElement>(null);
  const title =
    variant === 'main' ? 'Popular Categories' : 'Popular MTB Categories';
  return (
    <div className="flex flex-col gap-[1.6rem] p-4" ref={ref}>
      <div className="flex w-full justify-between">
        <h4 className="text-[2.4rem] font-semibold">{title}</h4>
        <div className="hidden gap-[1.6rem] sm:flex">
          {/* <Arrow
            variant="left"
            onClick={() => {
              if (container.current) {
                // scroll all the way left
                container.current.scrollLeft = 0;
              }
            }}
          /> */}
          <ChevronLeftIcon
            className="h-14 w-14 cursor-pointer text-brand-secondary"
            onClick={() => {
              if (container.current) {
                // scroll all the way left
                container.current.scrollLeft = 0;
              }
            }}
          />

          {/* <Arrow
            variant="right"
            onClick={() => {
              if (container.current) {
                // scroll all the way right
                container.current.scrollLeft = container.current.scrollWidth;
              }
            }}
          /> */}

          <ChevronRightIcon
            className="h-14 w-14 cursor-pointer text-brand-secondary"
            onClick={() => {
              if (container.current) {
                // scroll all the way left
                container.current.scrollLeft = container.current.scrollWidth;
              }
            }}
          />
        </div>
      </div>

      <div
        className="scroll-smooth-no-safari flex gap-[3rem] overflow-auto pb-4 sm:mx-0 sm:w-full sm:overflow-hidden sm:pb-0"
        ref={container}
      >
        {categories.map((category) => (
          <CategoryLink
            key={category.label}
            src={category.src}
            href={category.href}
            alt={category.alt}
            label={category.label}
            onClick={() => {
              logEvent('select_item', {
                items: [
                  {
                    item_id: category.label,
                    item_name: category.label,
                    item_category: 'popular_categories',
                  },
                ],
              });
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const PopularMTBCategories = () => {};

interface CategoryLinkProps {
  src: string;
  href: string;
  alt: string;
  label: string;
  onClick?: () => void;
}

const CategoryLink = ({
  src,
  href,
  alt,
  label,
  onClick,
}: CategoryLinkProps) => {
  return (
    <Link href={href} onClick={onClick}>
      <div className="group relative h-[25rem] w-[20rem] sm:h-[35rem] sm:w-[30rem]  ">
        {/* Bounce animation is applied in global.css */}

        <div className="h-[20rem] w-[20rem] overflow-hidden rounded-brand sm:h-[30rem] sm:w-[30rem]">
          <SafeImage
            src={src}
            alt={alt}
            key={src}
            height={300}
            width={300}
            classes="mx-auto inset-0 z-10 group-hover:scale-105 transition-all duration-300 ease-in-out"
          />
        </div>

        <div className="absolute top-0 z-20 h-[20rem] w-full rounded-brand  bg-gradient-to-b from-[rgba(0,0,0,0.1)] to-[rgba(0,0,0,0.9)] sm:h-[30rem]"></div>
        <span className="hover  absolute bottom-[1rem] left-[1rem] z-20 mt-4 text-[2.4rem] font-semibold transition-all group-hover:bottom-[6rem] group-hover:text-white">
          {label}
        </span>
      </div>
    </Link>
  );
};

export default PopularCategories;
