'use client';

import { SportCategory } from '@c/navigation/utils/categories';
import { motion } from 'framer-motion';
import SafeImage from '@ui/SafeImage';
import { getCDNUrl } from '@util/index';
import { useState } from 'react';

export default function SportCard({
  sport,
  onClick,
  noHover,
}: {
  sport: SportCategory;
  onClick?: () => void;
  noHover?: boolean;
}) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <motion.div
      className="group flex h-[40rem] w-[28.5rem] cursor-pointer flex-col justify-around rounded-xl pb-16 pt-6"
      initial={{ backgroundColor: '#F1F1F1', y: 0, scale: 1 }}
      whileHover={{
        backgroundColor: !noHover ? sport.color : '#F1F1F1',
      }}
      onMouseEnter={() => !noHover && setIsHovering(true)}
      onMouseLeave={() => !noHover && setIsHovering(false)}
      onClick={() => onClick?.()}
    >
      <motion.div
        initial={{ scale: 1 }}
        animate={{ scale: isHovering ? 1.1 : 1, left: isHovering ? 10 : 0 }}
        className="relative h-72 w-full"
      >
        <SafeImage
          src={getCDNUrl(sport.image ?? '')}
          className={`h-full object-cover object-right pr-32`}
          alt={sport.label}
        />
      </motion.div>

      <div className="flex w-full justify-center">
        <h1
          className={`text-[2rem] font-semibold ${
            !noHover && 'group-hover:text-brand-white'
          }`}
        >
          {sport.label}
        </h1>
      </div>
    </motion.div>
  );
}
